@import "./variables.scss";
@import "./nav.scss";

* {
    // outline: 1px solid blue !important;
    @include main-font();
}

html {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

body {
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
    background: $neo-bg-color;
}

.App {
    height: 100%;
    display: flex;
    overflow: hidden;
}

.right-aside {
    padding: 25px 25px 25px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.top-right {
    height: $headerHeight;
    display: inline-flex;
    flex-shrink: 0;
    width: 100%;
    // border-bottom: 2px solid grey;
}

.top-left {
    height: $headerHeight;
    flex-shrink: 0;
    width: 100%;
    // border-bottom: 2px solid grey;
}

main {
    flex-grow: 1;
    overflow-y: auto;
}

.blog-container,
.home-container,
.resume-container {
    margin: 10px 20px 10px 20px;
    max-width: 960px;
    line-height: 140%;

    h1 {
        font-size: 1.75em;
    }
    h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
        color: green;
    }
    h3 {
        font-size: 1.25em;
        margin-bottom: 10px;
        color: green;
    }
    p {
        margin: 10px 0px 10px 0px;
    }
    li {
        display: list-item;
        list-style-type: square;
        list-style-position: inside;
        margin: 2px;
    }
    div {
        width: 100%;
    }
    img {
        min-width: 0;
        width: 100%;
        margin: 5px 0px 5px 0px;
        outline: 1px solid black;
    }
}

.treegraph-container {
    display: flex;
    width: 100%;
    height: 1000px;
    margin: 20px;
}
