$main-font-color: rgb(40, 40, 40);
$icon-color-lightened: rgb(65, 65, 65);

@mixin main-font() {
    font-family: Helvetica, sans-serif !important;
    font-size: 1.01em !important;
    color: $main-font-color;
}

@mixin un-styled-links() {
    color: $main-font-color;
}

$navWidthMax: 300px;
$navWidthMin: 300px;

$navItemHeight: 47px;
$headerHeight: 0px;

$navItemTierTwoHeight: 35px;

// neomorphism
$neo-bg-color: #f5f5f5;
$neo-bg-color-alt: #e1e5ee;

$large-border-radius: 20px;
$medium-border-radius: 12px;
$small-border-radius: 7px;

$large-box-shadow-up: 16px 16px 32px #dfdfdf, -16px -16px 32px #ffffff;
$large-box-shadow-down: inset 16px 16px 32px #dfdfdf, inset -16px -16px 32px #ffffff;

$medium-box-shadow-up: 7px 7px 14px #dfdfdf, -7px -7px 14px #ffffff;
$medium-box-shadow-down: inset 7px 7px 14px #dfdfdf, inset -7px -7px 14px #ffffff;

$small-box-shadow-up: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
$small-box-shadow-down: inset 1px 1px 2px #dfdfdf, inset -1px -1px 2px #ffffff;
$small-box-shadow-hover: -2px -2px 5px #ffffff, 2px 2px 5px #dfdfdf;
$small-box-shadow-active: inset 1px 1px 2px #dfdfdf, inset -1px -1px 2px #ffffff;

@mixin neo-button-hover() {
    box-shadow: $small-box-shadow-hover;
}
@mixin neo-button-active() {
    box-shadow: $small-box-shadow-active;
}

@mixin neomorphism-large-up() {
    border-radius: $large-border-radius;
    background: $neo-bg-color;
    box-shadow: $large-box-shadow-up;
}
@mixin neomorphism-large-down() {
    border-radius: $large-border-radius;
    background: $neo-bg-color;
    box-shadow: $large-box-shadow-down;
}
@mixin neomorphism-medium-up() {
    border-radius: $medium-border-radius;
    background: $neo-bg-color;
    box-shadow: $medium-box-shadow-up;
}
@mixin neomorphism-medium-down() {
    border-radius: $medium-border-radius;
    background: $neo-bg-color;
    box-shadow: $medium-box-shadow-down;
}
@mixin neomorphism-small-up() {
    border-radius: $small-border-radius;
    background: $neo-bg-color;
    box-shadow: $small-box-shadow-up;
}
@mixin neomorphism-small-down() {
    border-radius: $small-border-radius;
    background: $neo-bg-color;
    box-shadow: $small-box-shadow-down;
}

// standard font size
$standard-font-size: 1.25em;

// left nav
$top-level-spacing: 5px;

// control panel form
// scroll bar
$scroll-bar-color: orange;
$scroll-bar-back-color: rgb(237, 223, 198);
